import {
  zendeskApi,
  zendeskFileUpload,
  generateFileUploadTokens,
} from "./zendeskService"
import { sendEmail } from "./emailService"
import { orderConfirmation } from "./emailTemplates"
import { formatNumber } from "./moneyFormat"
import { sendMessage } from "../services/telerivet"
import { handleError } from "../services/handleError"
import { getDateString } from "../components/forms/services/date"

const FROM_EMAIL = "MedGrocer <pulsecare@medgrocer.com>"

export async function sendOrder(
  calculatorDetails,
  orderDetails,
  fileArray,
  callback,
  templateTextMessage,
  errorCallback
) {
  const {
    customerFirstName,
    customerLastName,
    customerEmail,
    birthday,
    mobileNumber,
    shippingAddress,
    prescribingDoctor,
    details,
    subtotal,
    discountAmount,
    deliveryNotes,
    asepticDelivery,
    paymentMethod,
    bankName,
    changeFor,
    hospital,
    contactPerson,
    authorizedPerson,
    preferredDeliveryDate,
  } = orderDetails

  let { purchaseQuantity, medicineAssistance } = details.reduce(
    (totalQuantity, product) => {
      if (product.productTitle.includes("Medicine Assistance"))
        totalQuantity.medicineAssistance += parseInt(product.quantity)
      else totalQuantity.purchaseQuantity += parseInt(product.quantity)

      return totalQuantity
    },
    {
      purchaseQuantity: 0,
      medicineAssistance: 0,
    }
  )

  let nameToContact =
    contactPerson.length === 0
      ? authorizedPerson.fullName
      : `${customerFirstName} ${customerLastName}`
  let numberToContact =
    contactPerson.length === 0 ? authorizedPerson.mobileNumber : mobileNumber

  try {
    const uploadedFiles = await zendeskFileUpload(fileArray)
    const fileTokens = generateFileUploadTokens(uploadedFiles)
    let tags = ["order"]

    if (process.env.NODE_ENV !== "production") {
      tags.push("test")
    }
    let response = await zendeskApi(process.env.GATSBY_ZENDESK_EMAIL).post(
      "/requests.json",
      {
        request: {
          type: "order",
          subject: `${
            process.env.NODE_ENV === "production" ? "" : "[TEST]"
          } PCP Order from ${customerFirstName} ${customerLastName}`,
          requester: {
            name: `${customerFirstName} ${customerLastName}`,
            email: customerEmail,
          },
          tags: tags,
          comment: {
            body: `
          First Name: ${customerFirstName} 
          Last Name: ${customerLastName}
          Email: ${customerEmail}
          Contact Number: ${mobileNumber}
          Authorized Person to Receive: ${nameToContact}
          Number of Authorized Person: ${numberToContact}
          Birthday: ${birthday}
          Address Type: ${shippingAddress.addressType}
          Street Address: ${shippingAddress.streetAddress}
          Barangay: ${shippingAddress.barangay.value}
          City: ${shippingAddress.city.value}
          Province: ${shippingAddress.province.value}
          Calculator:
          Purchase Quantity: ${purchaseQuantity}
          Medicine Assistance: ${medicineAssistance}
          Total Quantity: ${purchaseQuantity + medicineAssistance}
          Medicines:
            ${details
              .map(product => {
                return (
                  product.productTitle +
                  " #" +
                  product.quantity +
                  ", Price: ₱ " +
                  formatNumber(product.vatex_unit_price)
                )
              })
              .join("\n")}
          Shipping: ${formatNumber(asepticDelivery)}
          Order Notes: ${
            deliveryNotes === "" ? "No delivery notes" : deliveryNotes
          }
          Payment Method: ${
            paymentMethod === "Bank Transfer/Deposit"
              ? `Bank Transfer/Deposit - ${bankName}`
              : paymentMethod || "N/A"
          }
          Preferred Delivery Date: ${getDateString({
            dateObject: preferredDeliveryDate,
          })}
          Prescribing Doctor: ${prescribingDoctor}
          Hospital/Clinic Name: ${
            hospital.name.value === "Other"
              ? hospital.otherHospital
              : hospital.name.value
          }
          Hospital/Clinic Address: ${hospital.city.value}, ${
              hospital.province.value
            }
          ---
          Total: ${formatNumber(subtotal - discountAmount + asepticDelivery)}
          Change for: ${
            changeFor === undefined
              ? "Not specified"
              : `${formatNumber(changeFor)}`
          }
          `,
            uploads: fileTokens,
          },
        },
      }
    )
    await sendEmail(
      customerEmail,
      FROM_EMAIL,
      `${
        process.env.NODE_ENV === "production" ? "" : "[TEST]"
      } Your MedGrocer Order`,
      orderConfirmation({ ...orderDetails, calculator: calculatorDetails })
    )
    if (templateTextMessage) {
      await sendMessage({
        recipient: mobileNumber,
        message: templateTextMessage({
          zendeskID: response.data.request.id,
        }),
        callback: () => callback(),
      })
    }
  } catch (error) {
    await sendEmail(
      [customerEmail, "paphelpdesk@medgrocer.com"],
      FROM_EMAIL,
      `${
        process.env.NODE_ENV === "production" ? "" : "[TEST]"
      } This order didn't reach Zendesk`,
      orderConfirmation({ ...orderDetails, calculator: calculatorDetails })
    )

    let errorMessage =
      "There was an error sending your enrollment. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}
// export const attachFiles = (id, fileArray, callback) => {
//   fileArray.forEach(file => {
//     zendeskApi()
//       .post(
//         `/uploads.json?filename=${file.name}`,
//         b64toBlob(
//           file.buffer.split(',')[1],
//           file.buffer.split(',')[0].match(/:(.*?);/)[1]
//         ),
//         {
//           headers: {
//             'Content-Type': 'application/binary',
//           },
//         }
//       )
//       .then(response => {
//         zendeskApi()
//           .put(`/requests/${id}.json`, {
//             request: {
//               comment: {
//                 body: file.name,
//                 uploads: [response.data.upload.token],
//               },
//             },
//           })
//           .then(response => {
//             console.log(`attachment uploaded: ${response.data}`)
//             callback()
//           })
//           .catch(error => {
//             console.log('error in zendesk attachment: ', error)
//             callback()
//           })
//       })
//       .catch(error => {
//         console.log('error is: ', error)
//       })
//   })
// }
