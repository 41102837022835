import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { navigate, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import { clearState } from "./actions"
import { FilesClear } from "../document-upload-content/dropzone-action"
import { sendOrder } from "../../utils/api"
import Message from "../elements/Message"
import ContentFold from "../page-content/content-fold"
import Checkbox from "../forms/checkbox/checkbox"
import Medicines from "./medicines"
import StickyActionFooter from "../page-content/sticky-action-footer"
import styles from "./cart.module.scss"
import CustomerDetails from "./customer-details"
import RequiredDocuments from "./required-documents"
import pdfIcon from "../../assets/images/pdf.png"
import { formatNumber } from "../../utils/moneyFormat"
import { withTranslation, Trans } from "react-i18next"
import { useContext } from "react"
import { AppContext } from "../../context/AppContext"
import { useState } from "react"
import { orderTextMessageTemplate } from "./templates/orderTextTemplate"

const OrderSummary = ({ t }) => {
  const [errorMessage, setErrorMessage] = useState("")
  const { state, dispatch } = useContext(AppContext)
  const { cart, filesArray: dropzoneFiles, calculator } = state
  const [internalState, setInternalState] = useState({})
  const [loading, setLoading] = useState(false)

  const ASEPTIC_DELIVERY_FEE = state.cart.asepticDelivery

  const handleSubmitError = async ({ message }) => {
    setLoading(false)
    setErrorMessage(message)
    await setInternalState({
      ...internalState,
      isSubmitting: false,
    })
  }

  const handleInputChange = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    setInternalState({ ...internalState, [name]: value })
  }

  const handleSendOrder = async event => {
    setLoading(true)
    event.preventDefault()
    await setInternalState({
      ...internalState,
      isSubmitting: true,
    })
    await sendOrder(
      calculator,
      cart,
      dropzoneFiles,
      handleSuccessCallback,
      orderTextMessageTemplate,
      handleSubmitError
    )
  }

  const handleSuccessCallback = async () => {
    await setInternalState({ ...internalState, isSubmitting: false })
    await dispatch({ type: "CLEAR_ALL_DETAILS" })
    await navigate("/complete")
  }

  const formatAddress = (
    streetAddress,
    barangay,
    cityMunicipality,
    province
  ) => {
    return (
      <Fragment>
        {streetAddress && `${streetAddress}, `} {barangay && barangay}
        <br />
        {cityMunicipality && `${cityMunicipality}, `} {province && province}
        <br />
      </Fragment>
    )
  }

  return (
    <ContentFold title={t("Review Order")} isCentered>
      <h4 className="has-text-centered is-size-4 has-text-weight-bold mt-2">
        {t("Delivery Details")}
      </h4>
      <CustomerDetails>
        <p className="has-text-weight-bold is-size-5">
          {cart.customerFirstName} {cart.customerLastName}
        </p>
        <p className="is-size-6">
          {formatAddress(
            cart.shippingAddress.streetAddress,
            cart.shippingAddress.barangay.value,
            cart.shippingAddress.city.value,
            cart.shippingAddress.province.value
          )}{" "}
          <br />
          <FontAwesomeIcon icon={faEnvelope} /> {cart.customerEmail} <br />
          <FontAwesomeIcon icon={faPhone} /> {cart.mobileNumber}
        </p>
        <p className="pr-3">{!!cart.changeFor === 0}</p>
      </CustomerDetails>
      <h4 className="has-text-weight-bold is-size-4 has-text-centered mt-2">
        {t("Order Details")}
      </h4>
      <p className="has-text-weight-bold help m-0 is-size-6">
        {t("Medicines")}
      </p>

      <Medicines products={cart.details} />
      <CustomerDetails>
        <div className="mb-2">
          {parseFloat(cart.discountAmount) !== 0 && (
            <div className="columns is-mobile help">
              <span className="column is-8-mobile has-text-danger pb-0">
                {t("Discount")}
              </span>
              <span className="column is-4-mobile has-text-danger pb-0 has-text-right">
                - ₱ {formatNumber(cart.discountAmount)}
              </span>
            </div>
          )}
          <div
            className={classNames(
              "columns is-mobile is-size-6",
              styles.asepticDelivery
            )}
          >
            <span className="column pb-0 is-8-mobile">Aseptic Delivery</span>
            <span className="column pb-0 is-4-mobile has-text-right">
              ₱ {formatNumber(ASEPTIC_DELIVERY_FEE)}
            </span>
          </div>
          <p className="help">
            To continuously deliver your medicines safely, we provide our riders
            the necessary precautionary protective gear and materials for every
            delivery transaction.
          </p>
          <div className="columns is-mobile is-size-5 mt-1">
            <span className="column is-8-mobile">{t("Subtotal")}</span>
            <span className="column is-4-mobile has-text-right">
              ₱{" "}
              {formatNumber(
                cart.subtotal - cart.discountAmount + ASEPTIC_DELIVERY_FEE
              )}
            </span>
          </div>
        </div>
      </CustomerDetails>
      <RequiredDocuments fileCount={dropzoneFiles.length}>
        {dropzoneFiles.map(fileURL => {
          const isPDF =
            fileURL.buffer.substring(
              "data:".length,
              fileURL.buffer.indexOf(";base64")
            ) === "application/pdf"
          return (
            <div className={classNames(styles.img64)}>
              <img
                src={isPDF ? pdfIcon : fileURL.buffer}
                className={
                  isPDF
                    ? classNames(styles.pdf, "image is-64x64")
                    : "image is-64x64"
                }
                alt="document"
              />
              <p className={classNames("mx-1", styles["image__filename"])}>
                {fileURL.name}
                <p
                  className={classNames("is-size-7", styles["image__filename"])}
                >
                  {fileURL.oldname}
                </p>
              </p>
            </div>
          )
        })}
      </RequiredDocuments>

      <div
        className={classNames(
          styles.notification,
          "notification help is-size-6"
        )}
      >
        {t(
          "Our pharmacist will verify your order via SMS. Your order will be delivered within the next working day after you confirm via SMS."
        )}
      </div>
      <Checkbox
        label={
          <Trans i18nKey="I agree to the terms and conditions">
            I agree to the{" "}
            <a href="/terms-and-conditions" target="_blank">
              <strong>terms and conditions</strong>
            </a>
          </Trans>
        }
        name="agree"
        handleInputChange={handleInputChange}
      />
      {errorMessage && (
        <Message color="danger" className="mt-2">
          {errorMessage}
        </Message>
      )}
      <StickyActionFooter>
        <div className="columns field is-grouped w-100">
          <div className="control column">
            <Link to="/payment">
              <button
                className="button w-100"
                disabled={internalState.isSubmitting}
              >
                {t("Back")}
              </button>
            </Link>
          </div>
          <div className="control column">
            <button
              className={classNames("button is-link w-100", {
                "is-loading": loading,
              })}
              onClick={handleSendOrder}
              disabled={
                !internalState.agree ||
                !dropzoneFiles.length ||
                !cart.details.length
              }
            >
              {t("Place Order")}
            </button>
          </div>
        </div>
      </StickyActionFooter>
    </ContentFold>
  )
}

export default withTranslation()(OrderSummary)
