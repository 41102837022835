import { formatNumber } from "./moneyFormat"
// import { Fragment } from 'react'

import {
  drDataPrivacyOptions,
  communicationsOptions,
  drProgramEndOptions,
  drWaiverOptions,
} from "../components/forms/enrollment-form/utils/consent-and-authorization-options"
import { getDateString } from "../components/forms/services/date"

const handleBuildData = input => {
  let output = ""
  input.forEach(data => {
    output += `<li>${data}</li>`
  })
  return output
}

export function orderConfirmation(order) {
  const {
    customerFirstName,
    customerLastName,
    mobileNumber,
    shippingAddress,
    details,
    couponCode,
    deliveryNotes,
    convenienceFee,
    asepticDelivery,
    paymentMethod,
    discountAmount,
    prescribingDoctor,
    hospital,
    changeFor,
    preferredDeliveryDate,
    contactPerson,
    authorizedPerson,
    bankName,
  } = order

  let { purchaseQuantity, medicineAssistance } = details.reduce(
    (totalQuantity, product) => {
      if (product.productTitle.includes("Medicine Assistance"))
        totalQuantity.medicineAssistance += parseInt(product.quantity)
      else totalQuantity.purchaseQuantity += parseInt(product.quantity)

      return totalQuantity
    },
    {
      purchaseQuantity: 0,
      medicineAssistance: 0,
    }
  )
  const address = `${shippingAddress.streetAddress} ${
    shippingAddress.barangay.value
  }, \n${shippingAddress.city.value || ""}\n${shippingAddress.province.value ||
    ""}`
  const formattedDetails = details
    .map(detail => {
      return `<tr>
          <td>${detail.productTitle
            .replace("(Entresto)", "")
            .replace("Entresto - ", "")
            .replace("(Medicine Assistance)", "- Medicine Assistance")}</td>
          <td style="text-align:center">${detail.quantity}</td>
          <td style="text-align:right">₱ ${formatNumber(
            parseFloat(detail.vatex_unit_price) * detail.quantity
          )}</td>
        </tr>`
    })
    .join("\n")

  const subtotal = parseFloat(
    details.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        parseFloat(currentValue.vatex_unit_price) *
          parseInt(currentValue.quantity)
      )
    }, 0)
  )
  const total = subtotal + parseFloat(discountAmount) + asepticDelivery

  const payment_details =
    paymentMethod === "COD"
      ? `Cash on Delivery (Change for ${formatNumber(changeFor)})`
      : `Bank Transfer/Deposit - ${bankName}`

  let nameToContact =
    contactPerson.length === 0
      ? authorizedPerson.fullName
      : `${customerFirstName} ${customerLastName}`
  let numberToContact =
    contactPerson.length === 0 ? authorizedPerson.mobileNumber : mobileNumber

  // const change = changeFor ? `(Change for ${formatNumber(changeFor)})` : ''
  return /*html*/ `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <title>MedGrocer Order</title>
        <style>
          @media only screen and (max-width: 770px) {
            .logo {
              width: 100%;
            }
          }

          @media screen {
            .logo {
              width: 30%;
            }
          }

          body {
            font-family: Lato, sans-serif;
          }

          table {
            border-collapse: collapse;
            width: 100%;
          }

          table,
          th,
          td {
            border: 1px solid black;
          }

          td {
            padding: 10px;
          }

          strong {
            color: #0d6d6e;
          }

          th {
            padding: 10px;
            background-color: #0d6d6e;
            color: white;
          }

          .container {
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-direction: row;
            padding: 25px;
            width: 100%;
            justify-content: center;
          }

          .content {
            width: 80%;
          }

          .is-primary {
            color: #0d6d6e;
          }

          .is-bordered {
            border: 1px solid black;
          }

          .is-right {
            text-align: right;
          }

          .is-center {
            text-align: center;
          }

          .no-wrap{
            white-space: nowrap;
          }

        </style>
      </head>

      <body>
        <div class="container">
          <div class="content">
            <img
              src="https://s3-ap-southeast-1.amazonaws.com/data.medgrocer.com/public/logo/mgsolo_teal.png"
              class="logo"
            />
            <p class="subtitle">Hi <b>${customerFirstName} ${customerLastName}</b>,</p>
            <p>Thank you for ordering from MedGrocer.</p>
            <p>
              Our pharmacist will text you during working hours 
              (Mon - Fri, 8:30 AM-4:30 PM) to verify your order details. 
              If you don't receive an SMS within the next working day, 
              please contact us at 0917 848 2088.
            </p>
            <p>
              Please note that the total amount below is not final until
              verified by our pharmacist via SMS.
            </p>

            <p class="subtitle is-primary"><b>Customer Details</b></p>
            <ul>
              <li><b>Mobile:</b> ${mobileNumber}</li>
              <li><b>Contact person:</b> ${nameToContact}</li>
              <li><b>Mobile number of contact person:</b> ${numberToContact}</li>
              <li><b>Delivery Address:</b> ${
                shippingAddress.addressType
              } ${address}</li>
              <li><b>Delivery Notes:</b> ${deliveryNotes}</li>
              <li><b>Payment Method:</b> ${payment_details} </li>
              <li><b>Preferred Delivery Date:</b> ${getDateString({
                dateObject: preferredDeliveryDate,
              })}</li>
            </ul>
            <p class="subtitle is-primary"><b>Prescription Details</b></p>
            <ul>
              <li><b>Total quantity needed:</b> ${purchaseQuantity +
                medicineAssistance}</li>
            </ul>
            <p class="subtitle is-primary"><b>Hospital/Clinic Details</b></p>
          
            <ul>
              <li><b>Prescribing Doctor:</b> ${prescribingDoctor}</li>
              <li><b>Hospital/Clinic:</b> ${
                hospital.name.value === "Other"
                  ? hospital.otherHospital
                  : hospital.name.value
              }</li>
              <li><b>Hospital/Clinic Address:</b> ${hospital.province.value}, ${
    hospital.city.value
  }</li>
            </ul>
            <br />
            <table class="table is-bordered">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
              ${formattedDetails}
                <tr>
                  <td colspan="2">Subtotal</td>
                  <td colspan="2" class="is-right no-wrap">₱ ${formatNumber(
                    subtotal
                  )}</td>
                </tr>
                ${
                  parseFloat(convenienceFee) === 0.0
                    ? ""
                    : `<tr>
                  <td colspan="2">Convenience Fee</td>
                  <td colspan="2" class="is-right no-wrap">₱ ${convenienceFee}</td>
                </tr>`
                }
                <tr>
                  <td colspan="2">Aseptic Delivery</td>
                  <td colspan="2" class="is-right no-wrap">₱ ${formatNumber(
                    asepticDelivery
                  )}</td>
                </tr>
                ${
                  parseFloat(discountAmount) === 0.0
                    ? ""
                    : `<tr>
                  <td colspan="2">Discount (${couponCode})</td>
                  <td colspan="2" class="is-right no-wrap">- ₱ ${formatNumber(
                    discountAmount
                  )}</td>
                </tr>`
                }
                <tr>
                  <td colspan="2"><b>Total</b></td>
                  <td colspan="2" class="is-right no-wrap"><b>₱ ${formatNumber(
                    total
                  )}</b></td>
                </tr>
              </tbody>
            </table>
            <br />
            <p class="subtitle is-primary"><strong>Consent and Authorization</strong></p>

            <p class="subtitle is-normal"><strong>Data Privacy</strong></p>
            <ul>
                ${handleBuildData(drDataPrivacyOptions)}
            </ul>

            <p class="subtitle is-normal"><strong>Communications</strong></p>
            <ul>
                ${handleBuildData(communicationsOptions)}
            </ul>

            <p class="subtitle is-normal"><strong>Program End</strong></p>
            <ul>
                ${handleBuildData(drProgramEndOptions)}
            </ul>

            <p class="subtitle is-normal"><strong>Waiver</strong></p>
            <ul>
                ${handleBuildData(drWaiverOptions)}
            </ul>
            

            <br />

            <div class="message is-info">
              <div class="message-body">
                This is an automated email.
                <b>For any revisions, please reply <u>CANCEL ORDER</u> to the
                  verification SMS our pharmacist will send you and place your
                  corrected order on
                  <a href="https://pulsecare.medgrocer.com">pulsecare.medgrocer.com</a>.</b>
                This is to prevent miscommunications and minimize inaccuracies.
              </div>
              <br />
              <div class="message-body">
                Thank you. We hope to serve you again in the future.
              </div>
            </div>
            <br />
            <div class="subtitle">
              Yours, <br />
              <b class="is-primary">The MedGrocer Team</b>
            </div>

            
          </div>
        </div>
      </body>
    </html>
  `
}
