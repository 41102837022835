import React from 'react'

const Checkbox = ({ handleInputChange, name, label }) => (
  <div className="field">
    <div className="control">
      <label className="checkbox checkbox-field">
        <input type="checkbox" name={name} onChange={handleInputChange} />
        {label}
        <span className="checkmark" />
      </label>
    </div>
  </div>
)

export default Checkbox
