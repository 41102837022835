import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import OrderSummary from '../components/cart/order-summary'

const ReviewOrder = ({ history, location }) => (
  <Layout location={location}>
    <SEO
      title="Review Your Order"
      keywords={[
        `novartis`,
        `medgrocer`,
        `pulse care`,
        `entresto`,
        `sacubitril-valsartan`,
      ]}
    />
    <OrderSummary history={history} />
  </Layout>
)

export default ReviewOrder
